// App.js
import React, { useEffect, useRef, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { Badge, Layout, Menu, notification, Button, Avatar } from 'antd';
import Home from './pages/Home';
import Users from './pages/Users';
import Settings from './pages/Settings';
import Meters from './pages/Meters';
import Rides from './pages/Rides';
import Registrations from './pages/Registrations';
import Drivers from './pages/Drivers';
import RejectedUsers from './pages/RejectedUsers';
import firebase from 'firebase';
import { Howl } from 'howler';
import { Packages } from './pages/Packages';
import Login from './pages/Login';
import Tolls from './pages/Tolls';
import { PackagesAssign } from './pages/PackagesAssign';
import VehicleInfo from './pages/VehicleInfo';

import {
  HomeOutlined,
  UserOutlined,
  SettingOutlined,
  CarOutlined,
  FileProtectOutlined,
  CloseCircleOutlined,
  GiftOutlined,
  DashboardOutlined,
  FormOutlined,
  LogoutOutlined,
  FieldTimeOutlined
} from '@ant-design/icons';
import Title from 'antd/es/typography/Title';
import UserSessions from './pages/UserSessions';
import DownloadRides from './pages/DownloadRides';
import DownloadOldRides from './pages/DownloadOldRides';


const { Sider, Content } = Layout;

const App = () => {
  const [pendingRegistrationsCount, setPendingRegistrationsCount] = useState(0);
  const [user, setUser] = useState(null); // Track user authentication state
  const previousCount = useRef(0); // Define previousCount using useRef

  useEffect(() => {
    // Firebase authentication listener
    const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
      setUser(user);
      if (user) {
        const res = await user.getIdTokenResult(true)
       
      }
    });

    return () => unsubscribe(); // Clean up on unmount
  }, []);

  useEffect(() => {
    const registrationsRef = firebase.firestore().collection('registrations');
    // Fetch initial count when component mounts
    registrationsRef.where('status', '==', 'pending').get().then(snapshot => {
      const count = snapshot.size;
      setPendingRegistrationsCount(count);
      previousCount.current = count;
    });

    // Set up snapshot listener for subsequent changes
    const unsubscribe = registrationsRef.where('status', '==', 'pending').onSnapshot(snapshot => {
      const count = snapshot.size;

      // Compare the incoming count with the previous count
      if (count > 0 && count !== previousCount.current) {
        const sound = new Howl({
          src: ['./src/assets/bell-sound.mp3'] // Provide path to your bell sound file
        });
        sound.play();
      }
    });

    return () => {
      // Unsubscribe from Firestore listener when component unmounts
      unsubscribe();
    };
  }, []);

  const handleLogout = async () => {
    try {
      await firebase.auth().signOut();
    } catch (error) {
      console.error('Logout error:', error.message);
    }
  };



  const Sidebar = ({ pendingRegistrationsCount, handleLogout }) => (
    <Sider width={250} theme="light" style={{ boxShadow: '2px 0 8px 0 rgba(29,35,41,.05)' }}>
      <div style={{
        height: '80px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderBottom: '1px solid #f0f0f0'
      }}>
        <img src={require('./assets/logo.png')} alt="Logo" style={{ height: '60px', width: 'auto' }} />
      </div>

      <div style={{ padding: '16px', borderBottom: '1px solid #f0f0f0' }}>
        <Avatar size={64} icon={<UserOutlined />} style={{ display: 'block', margin: '0 auto 8px' }} />
        <Title level={5} style={{ textAlign: 'center', margin: 0 }}>Admin User</Title>
      </div>

      <Menu
        mode="inline"
        style={{ height: 'calc(100% - 200px)', overflowY: 'auto' }}
        defaultSelectedKeys={['home']}
      >
        <Menu.Item key="home" icon={<HomeOutlined />}>
          <Link to="/">All Rides</Link>
        </Menu.Item>
        <Menu.Item key="donwloadRides" icon={<HomeOutlined />}>
          <Link to="/donwloadRides">Download Rides</Link>
        </Menu.Item>
        <Menu.Item key="donwloadOldRides" icon={<HomeOutlined />}>
          <Link to="/donwloadOldRides">Download Old Rides</Link>
        </Menu.Item>
        <Menu.Item key="users" icon={<UserOutlined />}>
          <Link to="/users">Drivers</Link>
        </Menu.Item>
        <Menu.Item key="Drivers" icon={<CarOutlined />}>
          <Link to="/Drivers">Approved Drivers</Link>
        </Menu.Item>
        <Menu.Item key="Rejected" icon={<CloseCircleOutlined />}>
          <Link to="/Rejected">Rejected Drivers</Link>
        </Menu.Item>
        <Menu.Item key="settings" icon={<SettingOutlined />}>
          <Link to="/settings">Settings</Link>
        </Menu.Item>
        <Menu.Item key="packages" icon={<GiftOutlined />}>
          <Link to="/packages">Assign packages sedan</Link>
        </Menu.Item>
        <Menu.Item key="packagesAssign" icon={<GiftOutlined />}>
          <Link to="/packagesAssign">Assign Packages Maxi</Link>
        </Menu.Item>
        <Menu.Item key="meters" icon={<DashboardOutlined />}>
          <Link to="/meters">Meters</Link>
        </Menu.Item>
        <Menu.Item key="Registrations" icon={<FormOutlined />}>
          <Link to="/Registrations">
            Registrations
            {pendingRegistrationsCount > 0 && (
              <Badge count={pendingRegistrationsCount} style={{ backgroundColor: '#f5222d', marginLeft: '8px' }} />
            )}
          </Link>
        </Menu.Item>
        <Menu.Item key="VehicleInfo" icon={<FileProtectOutlined />}>
          <Link to="/VehicleInfo">Vehicle Info</Link>
        </Menu.Item>
        {/* <Menu.Item key="UserSessions" icon={<FieldTimeOutlined />}>
          <Link to="/UserSessions">User Sessions</Link>
        </Menu.Item> */}
      </Menu>

      <div style={{ padding: '16px', borderTop: '1px solid #f0f0f0' }}>
        <Button
          onClick={handleLogout}
          type="primary"
          danger
          icon={<LogoutOutlined />}
          block
        >
          Logout
        </Button>
      </div>
    </Sider>
  );

  return (
    <Router>
      {user ? (
        <Layout style={{ minHeight: '100vh' }}>
          {/* Side Panel with Logo */}

          <Sidebar handleLogout={handleLogout} />

          <Layout>
            {/* Content Area */}
            <Content style={{ margin: '16px' }}>
              <Routes>
                <Route path="/" element={<Rides />} />
                <Route path="/donwloadRides" element={<DownloadRides />} />
                <Route path="/donwloadOldRides" element={<DownloadOldRides />} />
                <Route path="/home" element={<Home />} />
                <Route path="/users" element={<Users />} />
                <Route path="/Drivers" element={<Drivers />} />
                <Route path="/Rejected" element={<RejectedUsers />} />
                <Route path="/settings" element={<Settings />} />
                {/* <Route path="/tolls" element={<Tolls />} /> */}
                <Route path="/packages" element={<Packages />} />
                <Route path="/packagesAssign" element={<PackagesAssign />} />
                <Route path="/meters" element={<Meters />} />
                <Route path="/Registrations" element={<Registrations />} />
                <Route path="/VehicleInfo" element={<VehicleInfo />} />
                <Route path="/UserSessions/:userId" element={<UserSessions />} />

              </Routes>
            </Content>
          </Layout>
        </Layout>
      ) : (
        <Login />
      )}
    </Router>
  );
};

export default App;
